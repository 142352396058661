import { computed, defineComponent, ref, Ref } from "@vue/runtime-core";
import { useStore } from 'vuex'
import { StateProps } from '@/store'

import style from './index.module.less'
import BindPhone from '@/components/BindPhone'

export default defineComponent({
  setup() {
    const store = useStore<StateProps>();
    const userInfo = computed(() => store.state.userInfo)
    const isBindPhone: Ref<Boolean> = ref<boolean>(false)
    const handleChangeBindStatus = () => {
      isBindPhone.value = !isBindPhone.value
    }
    return () => {
      return (
        <div class={style.container}>
          <div class={style.title}>当前绑定的手机号</div>
          <div class={style.phone}>{userInfo?.value?.phone}</div>
          <div class={style.btn} onClick={() => handleChangeBindStatus()}>换绑</div>
          { isBindPhone.value && <BindPhone handleChangeBindStatus={handleChangeBindStatus} /> }
        </div>
      )
    }
  }
})
