import { get, post, versionNum } from './service'


export const wxLogin = (params: object) => get(`member/${versionNum}/wx_back`, params)

export const codeLogin = (data: object) => post(`member/${versionNum}/login`, data)

// 获取验证码
export const getPhoneCode = (data: object) => post(`member/${versionNum}/getCode`, data)

export const fetchCheckFiled = (data: object) => post(`member/${versionNum}/checkFiled`, data)

// 注册
export const fetchRegister = (data: object) => post(`member/${versionNum}/register`, data)

// 验证验证码
export const fetchCheckCode = (data: object) => post(`member/${versionNum}/checkCode`, data)

// 手机号绑定、解绑
export const fetchBindPhone = (params: object) => post(`member/${versionNum}/bindPhone`, params)