import { defineComponent, ref, Ref, computed, PropType, toRefs } from "@vue/runtime-core";
import { useStore } from 'vuex'
import { Toast } from 'vant';
import { getPhoneCode, fetchCheckFiled, fetchRegister, fetchCheckCode, fetchBindPhone } from '@/services/login'
import { phoneExp, codeExp } from '@/utils/regexp'

import style from './index.module.less'

export default defineComponent({

  props: {
    handleChangeBindStatus: {
      type: Function as PropType<() => void>,
      required: true
    }
  },

  setup(props) {
    const store = useStore();
    const userInfo = computed(() => store.state.userInfo)
    const { handleChangeBindStatus } = toRefs(props)
    const state: Ref<number> = ref(0)
    const stateList: Ref<string[]> = ref(['下一步', '换绑'])

    const phone = ref<number>() // 手机号
    const code: Ref<number | undefined> = ref() // 手机号
    const verificationCode:Ref<number> = ref(60)
    const isClickCode: Ref<boolean> = ref(false) // 用户是否已经点击了请求验证
    const isTimeRockon: Ref<boolean> = ref(false)
    const btnMsg = ref('获取验证码')
    const timer: Ref<any> = ref()
    const getCode = () => {
      if (!phoneExp.test(String(phone.value))) {
        Toast.fail('请输入正确的手机号')
        return;
      }
      if(!isTimeRockon.value){
        getPhoneCode({
          phone: phone.value
        }).then(res => {
          if (res) {
            Toast.success('发送成功')
            verificationCode.value = 60;
            isTimeRockon.value = true;
            timeVerification();
            isClickCode.value = true
          }
        })
      }
    }
    const timeVerification =  () => {
      if(verificationCode.value <= 0){
          verificationCode.value = 60;
          btnMsg.value = '获取验证码'
          isTimeRockon.value = false;
          return;
      }
      timer.value = setTimeout( () =>{
        verificationCode.value --;
        btnMsg.value = `${verificationCode.value}秒后再试`
          timeVerification();
      },1000)
    }

    const handleBtnClick = () => {
      if (!isClickCode.value) {
        Toast.fail('请先获取验证码')
        return;
      }
      if (!phoneExp.test(String(phone.value))) {
        Toast.fail('请输入正确的手机号')
        return;
      }
      if (!codeExp.test(String(code.value))) {
        Toast.fail('请输入正确的验证码')
        return;
      }
      if (state.value === 0) {
        fetchCheckCode({
          phone: phone.value,
          code: code.value
        }).then(res => {
          if (res) {
            state.value ++
            verificationCode.value = 60;
            btnMsg.value = '获取验证码'
            isTimeRockon.value = false;
            phone.value = undefined
            code.value = undefined
            clearInterval(timer.value)
          }
        })
      } else {
        fetchCheckFiled({
          key: 'phone',
          val: phone.value
        }).then(res => {
          if (!res) {
            fetchBindPhone({
              phone: phone.value,
              code: code.value,
              b_type: 2,
              m_id: userInfo.value.m_id,
            }).then(res => {
              store.commit({
                type: 'changeUserInfo',
                payload: res
              })
              setTimeout(() => {
                Toast.success('绑定成功')
                handleChangeBindStatus.value()
              }, 400)
            })
          } else {
            Toast.fail('手机号已存在')
          }
        })
      }
    }

    return () => {
      return (
        <div class={style.container}>
          <div class={style.content}>
            <div class={style.title}>{`请输入${state.value === 0 ? '原' : '新'}手机号`}</div>
            <input type="number" v-model={phone.value} class={style.input} placeholder="请输入手机号"></input>
            <div class={style.codeView}>
              <input type="number" v-model={code.value} class={style.codeInput} placeholder="验证码" />
              <div
                onClick={() => getCode()}
                class={verificationCode.value === 60 ? style.codeBtn : `${style.codeBtn} ${style.codeBtnGray}`}
              >{btnMsg.value}</div>
            </div>
            <div
              class={style.btn}
              onClick={handleBtnClick}
            >
              {stateList.value[state.value]}
            </div>
            <i onClick={handleChangeBindStatus.value} class={`iconfont ${style.closeIcon}`}>&#xe61f;</i>
          </div>
        </div>
      )
    }
  }
})
